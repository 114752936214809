import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@mui/material";
import ContactIcons from "./contacticons";
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import styled from "styled-components";

const DemoDiv = styled.div`
  margin-top:9rem;
  margin-bottom:32px;
  text-align:center;
`

const FreeDemo = styled.p`
  color:white;
  font-size: 3em;
  font-weight:bold;
  padding:8px;
`

const RequestDemo = () => {
  const [open, setOpen] = React.useState(false);
  const _handleClose = () => setOpen(false);
  return (
    <>
      <DemoDiv>
        <FreeDemo>
          Free Trail Demo
        </FreeDemo>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          disableElevation
          sx={{
            background: "white",
            height: '70px',
            borderRadius: '35px',
            color: 'black',
            fontSize: '18px',
            textTransform: 'none',
            marginTop: '32px',
            paddingLeft: '16px'
          }}
        >
          Request Demo
          <FastForwardOutlinedIcon color="primary" sx={{ fontSize: '30px', margin: '0 8px' }} />
        </Button>
      </DemoDiv>
      <Dialog
        open={open}
        keepMounted
        onClose={_handleClose}
        aria-labelledby="request-demo-confirmation"
        fullWidth
      >
        <DialogTitle id="rtmp-status-confirmation">
          For requesting a free demo, please contact our business team.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Business Team, Streamwell.</DialogContentText>
          <ContactIcons />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_handleClose}
            variant="contained"
            color="primary"
            disableElevation
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestDemo;
