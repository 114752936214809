import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import AppContext from "../../context/context";

// mui components
import IconButton from '@mui/material/IconButton';
import Stack from "@mui/material/Stack";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Switch from "@mui/material/Switch";
import Actions from "../../context/actions";
// custom components
import { DeleteIconButton, EditIconButton, PreviewIconButton, RefreshIconButton } from "../../components/ButtonIcons";
import { TableContainerStyled, TableCellStyled, Table, TableBody, TableRow } from "../../components/TableComponents";
import EditChannel from "../../components/Channel/Editchannel";
import Legend from "../../components/Legend";
import WarningModal from "../../components/Warningmodal";
import ChannelLinks from "./ChannelLinks";
import { Text } from "./ChannelDetailDisplayDiv";

//services
import { deleteChannel } from "../../service/channel.service";
import { refreshChannel } from "../../service/channel.service";
import FeatureService from "../../service/feature.service";
import AdminUtil from "../../utils/admin.util";
import { FormatDate } from "../../utils/date.util";
import ChannelRtmpEdit from "./ChannelRtmpEdit";



// styled
const HealthDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
`;


const ChannelTable = ({ channels, onUpdate }) => {

  // store variable
  const { store, dispatch } = useContext(AppContext);
  const { user } = store;
  const history = useHistory();
  // state vars
  const [_opendelete, setOpendelete] = useState(false);
  const [_openedit, setOpenedit] = useState(false);
  const [_openMore, setOpenMore] = useState(false);
  const [_channel, setChannel] = useState({});
  const feature_disabled = AdminUtil.isUser(user);

  const _dispatchRefreshChannel = () => {
    dispatch({
      type: Actions.SET_REFRESH_CHANNEL_FLAG,
    })
  }

  const _setCurrentChannel = async (channelSelected) => {
    return new Promise((resolve) => {
      setChannel(channelSelected);
      setTimeout(() => resolve(true), 100);
    })
  }

  const _openEditChannelModal = async (c) => {
    await _setCurrentChannel(c);
    setOpenedit(true)
  }

  const _openDeleteChannelDialog = async (c) => {
    await _setCurrentChannel(c);
    setOpendelete(true)
  }

  const _openMoreModal = async (c) => {
    await _setCurrentChannel(c)
    setOpenMore(true);
  }

  const _deleteChannel = async () => {
    await deleteChannel(_channel["id"]);
    setOpendelete(false);
    _dispatchRefreshChannel()
  };

  const _refreshChannelHealth = async (c) => {
    onUpdate();
    history.replace(`/home?domain=${c.domain}&channel=${c.name}`)
    await refreshChannel(c.name);
    dispatch({
      type: Actions.SET_REFRESH_CHANNEL_FLAG,
    })
  }

  const _toggleSwitchStatus = async (c) => {
    onUpdate();
    await FeatureService.editFeatures(c, {
      "switched_off": !c['feature']['switched_off'],
      "scheduled_off_at": null
    });
    history.replace(`/home?domain=${c.domain}&channel=${c.name}`)
    _dispatchRefreshChannel();
  }


  const _openPreviewWindow = (c) => {
    window.open(c['preview_url'], "blank")
  }


  return (
    <div>
      <TableContainerStyled>
        <Table aria-label="channel-list">
          <TableRow>
            <TableCellStyled align="left">No</TableCellStyled>
            <TableCellStyled align="left">Name</TableCellStyled>
            <TableCellStyled align="left">Created Date</TableCellStyled>
            <TableCellStyled align="center">Health</TableCellStyled>
            <TableCellStyled align="center">RTMP views</TableCellStyled>
            <TableCellStyled align="center">HLS Views</TableCellStyled>
            <TableCellStyled align="left">Status</TableCellStyled>
            {
              AdminUtil.isSuperAdmin(user) && <TableCellStyled align="left">Max RTMP</TableCellStyled>
            }
            <TableCellStyled align="left">Actions</TableCellStyled>
            <TableCellStyled align="left">More</TableCellStyled>
          </TableRow>
          <TableBody>
            {channels.map((channel, index) => {
              const feature = channel.feature;
              return (
                <TableRow key={channel.key + " " + index}>
                  <TableCellStyled align="left">{index + 1}</TableCellStyled>
                  <TableCellStyled align="left">{channel.name}</TableCellStyled>
                  <TableCellStyled align="left">{FormatDate(channel['created_at'])}</TableCellStyled>
                  <TableCellStyled align="center">
                    <HealthDiv>
                      {feature.health ? <Legend type="live" /> : <Legend type="idle" />}
                      <p>{feature.health ? "Live" : "Idle"}</p>
                    </HealthDiv>
                  </TableCellStyled>
                  <TableCellStyled align="center">{feature.health ? feature['rtmp_views'] : "-"}</TableCellStyled>
                  <TableCellStyled align="center">{feature.health ? feature['hls_views'] : "-"}</TableCellStyled>
                  <TableCellStyled align="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Text>Off</Text>
                      <Switch
                        onChange={() => _toggleSwitchStatus(channel)}
                        size="small"
                        checked={!feature["switched_off"]}
                        disabled={feature_disabled}
                      />
                      <Text>On</Text>
                    </Stack>
                  </TableCellStyled>
                  {
                    AdminUtil.isSuperAdmin(user) && (
                      <TableCellStyled align="center">
                        <ChannelRtmpEdit channel={channel} onSave={onUpdate} />
                      </TableCellStyled>

                    )
                  }
                  <TableCellStyled align="left">
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ marginTop: "8px" }} >
                      {
                        AdminUtil.isSuperAdmin(user) && (
                          <EditIconButton
                            onClick={() => _openEditChannelModal(channel)}
                            color="primary" />
                        )
                      }
                      <PreviewIconButton onClick={() => _openPreviewWindow(channel)} disabled={!feature['health']} color="primary" />
                      <RefreshIconButton onClick={() => _refreshChannelHealth(channel)} color="primary" />
                      {
                        (!(user['type'] === "USER")) && (<DeleteIconButton onClick={() => _openDeleteChannelDialog(channel)} color="error" />)
                      }
                    </Stack>
                  </TableCellStyled>
                  <TableCellStyled align="left">
                    <IconButton onClick={() => _openMoreModal(channel)} color="primary" aria-label="more-on-channels" component="label">
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCellStyled>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <WarningModal
        open={_opendelete}
        onClose={() => setOpendelete(false)}
        message={`Are you sure you want to delete the channel ${_channel?.name} ?`}
        onYes={_deleteChannel}
      />
      <EditChannel
        open={_openedit}
        onClose={() => setOpenedit(false)}
        channel={_channel}
        callback={_dispatchRefreshChannel}
      />
      <ChannelLinks channel={_channel} open={_openMore} onClose={() => setOpenMore(false)} />
    </div>
  );
};

export default ChannelTable;
