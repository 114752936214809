import React, { useState, useContext } from "react";
import AppContext from "../../context/context";

// mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import SaveIcon from '@mui/icons-material/Upload';

// components
import TxtField from "../TxtField";

// services
import { editUser } from "../../service/user.service";
// import PreviewSelect from "./PreviewSelect";
import UserDomainList from "./UserDomainList";
import { ValidateUserModel } from "../../utils/validation.util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditUser = ({ open, onClose, callback, userToEdit }) => {
  // store vaiable
  const { store } = useContext(AppContext);
  const { user } = store;

  // state variabled
  const [creating, setCreating] = useState(false);
  const [err, setErr] = useState(null);
  const [userObj, setUserObj] = useState({
    ...userToEdit,
  });
  const [password, setPassword] = useState("");
  // const [preview, setPreview] = useState(
  //   userObj.access.indexOf("preview") !== -1
  // );

  const handleChange = (e) => {
    setUserObj({
      ...userObj,
      [e.target.name]: e.target.value,
    });
  };

  const handleDomainListChange = (newDomainArray) => {
    setUserObj({
      ...userObj,
      domains: newDomainArray,
    });
  };


  const _validEntries = async () => {
    setErr(null);
    const validationResult = ValidateUserModel(user, userObj);
    if (validationResult !== null) {
      setErr(validationResult);
      return false;
    }
    return true;
  };

  const editExistingUser = async () => {
    setCreating(true);
    const isValid = await _validEntries();
    const _toEdit = {
      ...userObj,
      platform: userObj["platform"].toUpperCase(),
    };
    if (isValid) {
      const status = await editUser(user, _toEdit, password);
      if (status === "failed") {
        setErr("Error in editing user");
      } else {
        closePopup();
        callback();
      }
    }
    setCreating(false);
  };

  const closePopup = () => {
    setCreating(false);
    setErr(null);
    onClose();
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closePopup}
      aria-labelledby="create-user-form"
    >
      <DialogTitle id="create-user-form-title">{"Edit user"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="create-user-form-title-description">
          {err && <p style={{ color: "red" }}>{err}</p>}
          <TxtField
            id="username"
            label="Name"
            name="username"
            value={userObj.username}
            disabled={creating}
            onChange={handleChange}
          />
          <TxtField
            id="password"
            name="password"
            label="Password"
            value={password}
            disabled={creating}
            onChange={(ev) => setPassword(ev.target.value)}
          />
          {/* <TxtField
            id="platform"
            name="platform"
            label="Platform"
            value={userObj["platform"]}
            disabled={creating}
            onChange={handleChange}
          /> */}
          {/* <PreviewSelect preview={preview} onChange={(newPreviewValue) => setPreview(newPreviewValue)} /> */}
          <UserDomainList domains={userObj["domains"] || []} onChange={handleDomainListChange} />
        </DialogContentText>
        {creating && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!creating && (
          <Button
            onClick={editExistingUser}
            variant="contained"
            color="success"
            disableElevation
            startIcon={<SaveIcon />}
            sx={{ textTransform: "none" }}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditUser;
