import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const rows = 10;

export default function StatsPreloader() {
  return (
    <div style={{ marginTop: '32px' }} >
      {
        Array(rows).fill(1).map((_, i) => <Skeleton key={`stats-ch-${i}`} height={'64px'} />)
      }
    </div>
  )
}
