import axios from "axios";
import CacheService from "./cache.service";
import CACHEKEYS from "../cacheKeys";

const API = `/api/channel`;

const FeatureService = {

  editFeatures: async (channel, feature) => {
    try {
      await axios.put(`${API}`, {
        ...channel,
        feature
      });
      CacheService.remove(CACHEKEYS.FETCH_CHANNELS);
      return true;
    } catch (error) {
      return false;
    }
  },
}

export default FeatureService;