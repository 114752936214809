import Constants from "../constants";
const emialRegex = /\S+@\S+\.\S+/;
const ValidateUserModel = (adminUser, newUser) => {
  const { username, domains } = newUser;
  if (username.length === 0) {
    return "Username cannot be empty";
  }

  if (!emialRegex.test(username)) {
    return "Invalid username.Should be a valid email"
  }


  if (domains.length === 0) {
    return "Minimum one domain have to be added";
  }

  if (adminUser["type"] === Constants.user_type.SUPER_ADMIN) {
    return null;
  }


  let domainsToLimitMap = {};

  for (let d of adminUser["domains"]) {
    domainsToLimitMap[d["name"]] = d["channel_limit"];
  }
  //checking if added domain belongs to the user
  for (let d of domains) {
    if (domainsToLimitMap[d["name"]] === undefined) {
      return "User not having access to create domain"
    }
  }

  return null;
}

export {
  ValidateUserModel
}