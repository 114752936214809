import styled from "styled-components";

const Links = styled.div`
  margin-top: -24px;
`;

const CopyDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  padding-left:0px;
  margin-top: 18px;
`;

const Header = styled.p`
  padding-bottom: 4px;
  font-weight: bold;
`;

const Text = styled.p``;

export { Links, CopyDiv, Header, Text }