import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";


const btnStyle = {
  borderRadius: '25px',
  fontSize: '18px',
  textTransform: 'none',
  marginTop: '32px'
}

const AboutusDiv = styled.div`
  background-color:#ffffff;
  padding:32px 0;
`
const Header = styled.p`
  text-align:center;
  font-size:2em;
  font-weight:bold;
  margin:32px 0;
`

const SupportDiv = styled.div`
  text-align:center;
`

const SupportText = styled.p`
  text-align:center;
  font-size:2em;
  font-weight:bold;
`

const Aboutus = () => {
  const _openTelephone = () => {
    window.open("tel:7904037932", "_blank");
  };
  const _openEmail = () => {
    window.open(
      "mailto:streamiswell@gmail.com&subject=Streamwell enquiry - Reg",
      "_blank"
    );
  };
  return (
    <AboutusDiv>
      <Header>
        What kind of help do you need today?
      </Header>
      <div style={{ display: 'flex', justifyContent: 'space-around', padding: '0 20%' }} >
        <SupportDiv>
          <img
            alt="Contact Us"
            width="350px"
            height="350px"
            src="aboutus.jpg"
          />
          <SupportText>
            Connect with us
          </SupportText>
          <Button
            onClick={_openTelephone}
            variant="contained"
            disableElevation
            sx={btnStyle}
            startIcon={
              <CallIcon
                fontSize="large"
                style={{ marginRight: "8px", cursor: "pointer" }}
              />}
          >
            Call sales
          </Button>
        </SupportDiv>
        <SupportDiv>
          <img
            alt="Contact Us"
            width="300px"
            height="300px"
            src="sendemail.jpeg"
            style={{ marginBottom: '50px' }}
          />
          <SupportText >
            Send your query
          </SupportText>
          <Button
            onClick={_openEmail}
            variant="contained"
            disableElevation
            sx={btnStyle}
            startIcon={
              <EmailIcon
                fontSize="large"
                style={{ marginRight: "8px", cursor: "pointer" }}
              />
            }
          >
            Email us
          </Button>
        </SupportDiv>
      </div>
    </AboutusDiv>
  );
};

export default Aboutus;
