import React, { useState, useEffect } from "react";
import styled from "styled-components";

// mui-components
import { Button, Stack } from "@mui/material";
//icons
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
// import BwEnabled from "@mui/icons-material/HourglassEmptyOutlined";
// import BwDisabled from "@mui/icons-material/HourglassDisabledOutlined";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

// custom components
import Preloader from "../../components/Preloader";
import CreateNewChannel from "../../components/Channel/Createchannel";
import { TableContainerStyled, TableCellStyled, Table, TableBody, TableRow,TableHeaderText } from "../../components/TableComponents";

// services
import {
  getServers,
  rebootServer,
  // limitStatus,
  // startLimit,
  // stopLimit,
  deleteServer,
  syncIps
} from "../../service/domain.service";
import EditDomain from "../../components/Domain/EditDomain";
import AddNewDomain from "../../components/Domain/AddNewDomain";

// styled
const ServersPage = styled.div``;

const Info = styled.p`
  text-align: right;
  margin-bottom: 8px;
  display:flex;
  justify-content:flex-end;
  gap:8px;
`;

let prevIp = "0.0.0.0.";

const ServersS = ({ map }) => {
  // stat variabled
  const [servers, setServerList] = useState([]);
  const [_activeServer, setActiveServer] = useState("");
  const [_booting, setBooting] = useState(false);
  const [_open, setOpen] = useState(false);
  const [_new, setNew] = useState(false);
  const [_opencreate, setOpencreate] = useState(false);
  const [_server, setServer] = useState("");

  const _loadAllServers = async () => {
    let ss = await getServers();
    ss.sort((a, b) => a["current_ip"].localeCompare(b["current_ip"]));
    setServerList(ss);
    setActiveServer(ss[0]);
  };

  const _rebootServer = async (_sname) => {
    setBooting(true);
    await rebootServer(_sname);
    setTimeout(() => {
      setBooting(false);
      setOpen(false);
    }, 4 * 1000);
  };

  const _editDomain = async (_s) => {
    setActiveServer(_s);
    setTimeout(() => {
      setOpen(true);
    });
  };

  const _deleteServer = async (_sid) => {
    setServerList([]);
    await deleteServer(_sid);
    _loadAllServers();
  };

  const _canRenderIp = (ip) => {
    if (ip === prevIp) {
      return false;
    }
    prevIp = ip;
    return true;
  }

  const _syncIpAndServers = async () => {
    setServerList([]);
    await syncIps();
    _loadAllServers();
  }


  useEffect(() => {
    _loadAllServers();
    prevIp = "0.0.0.0.";
    // eslint-disable-next-line
  }, []);

  if (_booting) {
    return <Preloader message={"Rebooting server..."} />;
  }

  if (servers.length === 0) {
    return <Preloader message={"Loading servers..."} />;
  }



  return (
    <ServersPage>
      <Info>
        <Button
          onClick={() => setNew(true)}
          size="small"
          variant="contained"
          startIcon={<AddIcon />}
          disableElevation
        >
          New Domain
        </Button>
        <Button
          onClick={_syncIpAndServers}
          size="small"
          variant="contained"
          startIcon={<SyncIcon />}
          disableElevation
        >
          Sync IP
        </Button>
      </Info>
      <TableContainerStyled>
        <Table aria-label="channel-list">
          <TableRow>
            <TableCellStyled style={{ fontWeight: 'bold' }} align="left">
              <TableHeaderText>IP</TableHeaderText>
            </TableCellStyled>
            <TableCellStyled align="left">
              <TableHeaderText>Domain</TableHeaderText>
            </TableCellStyled>

            <TableCellStyled align="left">
              <TableHeaderText>Total Server Limit</TableHeaderText>
            </TableCellStyled>
            <TableCellStyled align="left">
              <TableHeaderText># Channels</TableHeaderText>
            </TableCellStyled>

            <TableCellStyled align="left">
              <TableHeaderText>Actions</TableHeaderText>
            </TableCellStyled>
            <TableCellStyled align="left">
              <TableHeaderText>Delete</TableHeaderText>
            </TableCellStyled>
          </TableRow>
          <TableBody>
            {servers.map((s, index) => {
              const _sname = s["name"];
              const _limit = s["channel_limit"];
              const _used = map[_sname] || "N/A";
              const _canRedner = _canRenderIp(s["current_ip"]);
              return (
                <TableRow key={s.id + " " + index}>
                  <TableCellStyled align="left">{_canRedner && s["current_ip"]}</TableCellStyled>
                  <TableCellStyled align="left">{_sname}</TableCellStyled>
                  <TableCellStyled align="left">{_limit}</TableCellStyled>
                  <TableCellStyled align="left">{_used}</TableCellStyled>
                  <TableCellStyled align="left">
                    <Stack direction="row" spacing={2}>
                      <Button
                        sx={{ textTransform: "none" }}
                        size="small"
                        disableElevation
                        disabled={_used >= _limit}
                        variant="outlined"
                        onClick={() => {
                          setServer(_sname);
                          setOpencreate(true);
                        }}
                      >
                        Add Channel
                      </Button>
                      <Button
                        onClick={() => _editDomain(s)}
                        sx={{ textTransform: "none" }}
                        disableElevation
                        size="small"
                        variant="outlined"
                      >
                        Edit
                      </Button>
                      {
                        _canRedner && <Button
                          sx={{ textTransform: "none" }}
                          disableElevation
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => _rebootServer(_sname)}
                        >
                          Reboot
                        </Button>
                      }
                    </Stack>
                  </TableCellStyled>
                  <TableCellStyled color="primary" align="left">
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => _deleteServer(s.id)}
                    />
                  </TableCellStyled>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <EditDomain
        open={_open}
        server={_activeServer}
        onClose={() => {
          setActiveServer(_activeServer);
          setOpen(false);
        }}
        callback={() => {
          setOpen(false);
          _loadAllServers();
        }}
      />
      <AddNewDomain
        open={_new}
        onClose={() => {
          setNew(false);
        }}
        callback={() => {
          setNew(false);
          _loadAllServers();
        }}
      />
      <CreateNewChannel
        open={_opencreate}
        onClose={() => setOpencreate(false)}
        callback={() => { }}
        server={_server}
      />
    </ServersPage>
  );
};



export default ServersS;
