import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

// styled
const TableContainerStyled = styled(TableContainer)`
  background-color: #ffffff;
  border-radius: 16px;
`;

const TableCellStyled = styled(TableCell)`
  padding: 12px 0;
`;

const TableHeaderText = styled.p`
  font-weight: bold;
`

export { Table, TableBody, TableRow, TableContainerStyled, TableCellStyled,TableHeaderText };