import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import AppConext from "../../context/context";
import Actions from "../../context/actions";
// mui
import LockIcon from "@mui/icons-material/Lock";


// services
import { getApp } from "./login.config";
import Devices from "../../Devices";
import UserNamePassword from "../../components/Login/UserNamePassword";
import Waves from "./waves";
// import CACHEKEYS from "../../cacheKeys";

// styled
const LoginPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  margin: 1px auto;
  //responsive
  @media ${Devices.tablet} {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1;
  // overflow-x: hidden;
  // position: relative;

  //responsive
  @media ${Devices.tablet} {
    flex: 2;
  }
`;

const Content = styled.div`
  height: 100%;
  background-color: rgba(5, 15, 102, 0.5);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 32px;
`;

const Name = styled.p`
  font-weight: bold;
  font-size: 80px;
  margin-top: -32px;
  padding-bottom:32px;
  margin-left:-2px;
  //responsive
  @media ${Devices.tablet} {
    font-size: 40px;
  }
`;

const Desc = styled.p`
  font-size: 22px;
  margin-bottom: 8px;
  //responsive
  @media ${Devices.tablet} {
    font-size: 12px;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(5, 15, 102, 0.5);

  //responsive
  @media ${Devices.tablet} {
    flex: 4;
  }
`;

const LoginDiv = styled.div`
  width: 50%;
  margin: auto;
  padding: 24px 16px;
  background-color: #ffffff;
  border-radius: 16px;
  @media ${Devices.tablet} {
    margin-top: -64px;
    width: 80%;
  }
`;

const InputArea = styled.div`
  margin: 16px 0;
`;

const Centerp = styled.p`
  text-align: center;
  font-size: 16px;
  margin-bottom: 4px;
  //responsive
  @media ${Devices.tablet} {
    margin-bottom: 0;
  }
`;

const LoginOthers = () => {

  // store varibles
  const { dispatch } = useContext(AppConext);

  // state variables
  const [_app, setApp] = useState({
    name: "",
    phone: "",
  });

  const _setAppName = () => {
    const location = window.location.href;
    const _a = getApp(location);
    setApp(_a);
    dispatch({
      type: Actions.SET_APPNAME,
      payload: _a,
    });
  };

  useEffect(() => {
    _setAppName();
    // _checkAutoLogin();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <LoginPage>
        <Left>
          <Content>
            <Name>{_app.name}</Name>
            <Desc>Dedicated streaming service provider.</Desc>
            <Desc>For contact {_app.phone}.</Desc>
          </Content>
          <Waves />
        </Left>
        <Right>
          <LoginDiv>
            <Centerp
              style={{
                fontWeight: "bold",
              }}
            >
              Welcome back
            </Centerp>
            <Centerp>Login to continue</Centerp>
            <Centerp>
              <LockIcon color="primary" fontSize="large" />
            </Centerp>
            <InputArea>
              <UserNamePassword namePlaceholder="" />
            </InputArea>
            <Centerp
              style={{
                marginTop: "16px",
                opacity: "0.6",
              }}
            >
              &copy; {_app.name.toLowerCase()}
            </Centerp>
          </LoginDiv>
        </Right>
      </LoginPage>
    </>
  );
};

export default LoginOthers;
