import * as React from 'react';
import styled from "styled-components";
//comps
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import TxtField from "../TxtField";
import Collapse from '@mui/material/Collapse';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';

//styled-comps
const UserDomainListDiv = styled.div`
  display:flex;
  gap:8px;
  margin-top:8px;
  align-items:center;
`

export default function UserDomainList({ domains = [], onChange }) {
  const [open, setOpen] = React.useState(true);

  const addNewDomain = () => {
    domains.push({ "name": "N/A", "channel_limit": 1 });
    onChange(domains)
  }

  const removeDomain = (dName) => {
    let newDomains = [];
    domains.forEach((d) => {
      if (d !== dName) {
        newDomains.push(d);
      }
    })
    onChange(newDomains);
  }

  const handleChange = (index, domainName, channelLimit) => {
    let newDomains = [];
    domains.forEach((d, i) => {
      if (i === index) {
        newDomains.push({ name: domainName, channel_limit: channelLimit })
      } else {
        newDomains.push(d);
      }
    })
    onChange(newDomains);
  }

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{ width: '100%' }}
      component="nav"
    >
      <ListItemButton onClick={handleClick}>
        <ListItemText sx={{ marginLeft: "-16px" }} primary="Domains" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {
          domains?.map((d, index) => (
            <UserDomainListDiv>
              <TxtField
                sx={{ flex: 2 }}
                id="user-list-domain"
                name="user-list-domain"
                label="Domain"
                value={d["name"]}
                onChange={(e) => handleChange(index, e.target.value, d["channel_limit"])}
              />
              <TxtField
                sx={{ flex: 1 }}
                id="user-list-channel-limit"
                name="user-list-channel-limit"
                label="Limit"
                value={d["channel_limit"]}
                onChange={(e) => handleChange(index, d["name"], +(e.target.value))}
                type="number"
              />
              <IconButton onClick={() => removeDomain(d)} >
                <RemoveIcon sx={{ color: "red" }} />
              </IconButton>
            </UserDomainListDiv>
          ))
        }
        <Button
          onClick={addNewDomain}
          variant="outlined"
          disableElevation
          size='small'
          sx={{ textTransform: "none", marginTop: "8px" }}
        >
          Add
        </Button>
      </Collapse>
    </List>
  );
}
