import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Preloader from './Preloader';
import AppConext from '../context/context';

export default function GlobalLoader() {

  const { store } = React.useContext(AppConext);
  const { globalLoader } = store;

  React.useEffect(() => { }, [globalLoader.loading]);


  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={globalLoader.loading}
      >
        <Preloader message={globalLoader.message} />
      </Backdrop>
    </div>
  );
}
