import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const pages = ['Solutions', 'Pricing', 'Contact', 'FAQs'];

const Logo = () => {
  return React.useMemo(() => {
    return <img
      src="logo.png"
      alt="streamwell-logo"
      width="150"
      height="42"
      style={{
        marginRight: "8px",
        marginTop: "4px",
      }}
    />
  },[])
}

const NavSmallScreen = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return <>
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {pages.map((page) => (
          <MenuItem key={page} onClick={handleCloseNavMenu}>
            <Typography textAlign="center">{page}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
    <Box sx={{
      mr: 2,
      display: { xs: 'flex', md: 'none' },
      flexGrow: 1,
    }} >
      <Logo />
    </Box>
  </>
}

function NavBar() {

  const openPanel = () => {
    window.open(process.env.REACT_APP_APPURL + "/login", "_blank");
  };

  return (
    <AppBar elevation={0} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            flexGrow: 2,
          }} >
            <Logo />
          </Box>
          <NavSmallScreen />
          <Box sx={{
            flexGrow: 1,
            display: {
              xs: 'none',
              md: 'flex'
            },
            gap: '32px'
          }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => { }}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  textTransform: 'none',
                  fontSize: '18px'
                }}
              >
                {page}
              </Button>
            ))}
            <Button
              onClick={openPanel}
              sx={{
                my: 2,
                color: 'white',
                display: 'block',
                textTransform: 'none',
                fontSize: '18px',
                border: '2px solid white'
              }}
              variant="outlined" >
              Login
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;