import React from "react";

import { Box } from "@mui/system";
import UserNamePassword from "../../components/Login/UserNamePassword";
import Waves from "./waves";
import LoginComponents from "../../components/Login/LoginComponents";

const { LoginPage,
  Left,
  Right,
  Phrase,
  LogoName,
  LogoNameName,
} = LoginComponents;


const LoginStreamwell = () => {
  return (
    <LoginPage>
      <Left>
        <LogoName >
          <img alt="streamwell-logo" src="logo192.png" height="50" width="50" />
          <LogoNameName>Streamwell</LogoNameName>
        </LogoName>
        <Box sx={{
          width: 350, '@media (max-width: 425px)': {
            width: 300
          }
        }} >
          <UserNamePassword namePlaceholder="streamiswell@gmail.com" />
          <p style={{ textAlign: 'center', marginTop: '48px' }} >
            For contact +91 79040 37932
          </p>
          <p style={{ textAlign: 'center', marginTop: '8px' }} >
            &copy; 2023 streamwell
          </p>
        </Box>
      </Left>
      <Right>
        <Phrase>
          Securely stream your videos
        </Phrase>
        <Phrase>
          with <span style={{ color: '#050f66', fontWeight: "bold" }} > Stream</span>
          <span style={{ color: '#ff6551', fontWeight: "bold" }} >well </span>
          !!!
        </Phrase>
        <img
          src={`landingbg.png`}
          alt="streamwell-logo"
          width="550px"
          height="550px"
          style={{
            alignSelf: 'center',
          }}
        />
      </Right>
      <Waves />
    </LoginPage>
  );
};


export default LoginStreamwell;
