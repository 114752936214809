const FormatDate = (d) => {
  const dateObj = new Date(d);
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  if(month < 10) month = "0"+month.toString();
  if(day < 10) day = "0"+day;
  const formatted = day + "-" + month + "-" + year;
  return formatted;
}

export {
  FormatDate
}