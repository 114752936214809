import React from "react";
import Constants from "../../constants";
import AppContext from "../../context/context";
import ServersAdminUser from "./server.adminuser";
import ServersSuperAdmin from "./server.superadmin";

const Servers = () => {
  const { store } = React.useContext(AppContext);
  const [map, setMap] = React.useState({});
  const { user, servers, channels } = store;

  const _setMapCount = () => {
    const _m = {};
    for (let i = 0; i < servers.length; i++) {
      _m[servers[i]] = 0;
    }
    for (let i = 0; i < channels.length; i++) {
      const n = channels[i].domain;
      _m[n] = _m[n] + 1;
    }
    setMap(_m);
  };

  React.useEffect(() => {
    _setMapCount();
    // eslint-disable-next-line
  }, [user, servers, channels]);

  if (user["type"] === Constants.user_type.SUPER_ADMIN) {
    return <ServersSuperAdmin map={map} />;
  }

  return <ServersAdminUser servers={user["domains"]} map={map} />;
};

export default Servers;
