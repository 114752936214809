import styled from "styled-components";

import Devices from "../../Devices";
// styled
// styled
const LoginPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  // background:#E8F0FE;
  //responsive
  @media ${Devices.tablet} {
    flex-direction: column;
  }
  @media ${Devices.mobileL} {
    padding-top: 20px; 
    flex-direction: column-reverse;
  }
`;

const Left = styled.div`
  flex-basis:40%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  //responsive
  @media ${Devices.tablet} {
    flex: 2;
  }
`;

const Right = styled.div`
  flex-basis:60%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  @media ${Devices.tablet} {
    flex: 4;
  }
`;

const Phrase = styled.p`
  font-size:2.4rem;
  line-height:2.5rem;
`

const LogoName = styled.div`
  display:flex;
  align-items:center;
  gap:16px;
  margin-bottom: 64px;
  @media ${Devices.mobileM} {
    margin-top: 24px; 
    margin-bottom: 24px;
  }
`

const LogoNameName = styled.p`
  font-size:3rem;
  @media ${Devices.mobileM} {
    font-size: 1.7rem;
  }
`

const WavesDiv = styled.div`
@media ${Devices.tablet} {
  display: none;
}
@media ${Devices.mobileM} {
  display: none;
}
`

const ExportObject = {
  LoginPage,
  Left,
  Right,
  Phrase,
  LogoName,
  LogoNameName,
  WavesDiv
}
export default ExportObject