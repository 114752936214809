import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

const EditIconButton = (props) => {
  return (
    <IconButton {...props}>
      <EditIcon />
    </IconButton>)
}

const DeleteIconButton = (props) => {
  return (
    <IconButton {...props}>
      <DeleteIcon />
    </IconButton>)
}

const RefreshIconButton = (props) => {
  return (
    <IconButton {...props}>
      <RefreshIcon />
    </IconButton>)
}

const PreviewIconButton = (props) => {
  return (
    <IconButton {...props}>
      <VisibilityIcon />
    </IconButton>)
}

const AddIconButton = (props) => {
  return (
    <IconButton {...props}>
      <ControlPointOutlinedIcon />
    </IconButton>)
}

const MinusIconButton = (props) => {
  return (
    <IconButton {...props}>
      <RemoveCircleOutlineOutlinedIcon />
    </IconButton>)
}

export {
  EditIconButton,
  DeleteIconButton,
  RefreshIconButton,
  PreviewIconButton,
  AddIconButton,
  MinusIconButton
}