import axios from "axios";
import CacheService from "./cache.service";
import CACHEKEYS from "../cacheKeys";
import Constants from "../constants";

const API = `/api/channel`;
const API_MOBILE_CHANNEL = `/api/mobile/channel`;

const _constructChannel = (user, channelname, server) => {
  const chname = channelname.toLowerCase().replace(" ", "");
  const newchannel = {
    name: chname,
    key: chname,
    domain: server,
    rtmp: `rtmp://${server}/live/${chname}`,
    hls: `https://${server}/hls/${chname}.m3u8`,
    preview_url: `https://${server}/play/${chname}`,
    token: `${chname}?psk=${chname}&token=${chname}`,
    stream: `rtmp://${server}/live`,
    status: true,
    created_by: user["id"]
  };
  return newchannel;
};

const createChannel = async (user, channelname, server) => {
  try {
    const newchannel = _constructChannel(user, channelname, server);
    await axios.post(`${API}`, newchannel);
    CacheService.remove(CACHEKEYS.FETCH_CHANNELS);
    return "success";
  } catch (error) {
    return "failed";
  }
};

const getChannels = async (user) => {
  try {
    const cachkey = CACHEKEYS.FETCH_CHANNELS;
    const cachevalue = CacheService.get(cachkey);
    if (cachevalue !== null) return cachevalue;
    const response = await axios.get(`${API}/${user.id}`);
    const data = response.data.payload || [];
    const servers = [...new Set(data.map((c) => c.domain))];
    servers.sort();
    const dataToCache = {
      data,
      servers,
    };
    CacheService.set(cachkey, dataToCache);
    return dataToCache;
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteChannel = async (channelid) => {
  try {
    await axios.delete(`${API}/${channelid}`);
    CacheService.remove(CACHEKEYS.FETCH_CHANNELS);
    return true;
  } catch (error) {
    return false;
  }
};

const getChannelDetailsByName = async (channelName) => {
  try {
    const response = await axios.get(`${API}/detail/${channelName}`);
    const data = response.data;
    if (data.status === "success") return data.payload;
    return null;
  } catch (error) {
    return null;
  }
};

const editchannel = async (channel, user) => {
  if (user["type"] === Constants.user_type.SUPER_ADMIN) {
    try {
      await axios.put(`${API}`, channel);
      CacheService.remove(CACHEKEYS.FETCH_CHANNELS);
      return "success";
    } catch (error) {
      return "failed";
    }
  }
  return "failed";
};

const refreshChannel = async (channelName) => {
  try {
    await axios.post(`${API}/refresh/${channelName}`);
    CacheService.remove(CACHEKEYS.FETCH_CHANNELS);
    return "success";
  } catch (error) {
    return "failed";
  }
};

const ChannelService = {
  getMobileChannelsByUserId: async (user) => {
    try {
      const cachkey = CACHEKEYS.FETCH_CHANNEL_MOBILES;
      const cachevalue = CacheService.get(cachkey);
      if (cachevalue !== null) return cachevalue;
      const response = await axios.get(`${API_MOBILE_CHANNEL}/${user["id"]}`);
      const data = response.data.payload || [];
      data.sort((a, b) => a["channel_no"] - b["channel_no"]);
      CacheService.set(cachkey, data);
      return data;
    } catch (error) {
      return [];
    }
  },
  createMobileChannel: async (channel) => {
    try {
      await axios.post(`${API_MOBILE_CHANNEL}`, channel);
      CacheService.remove(CACHEKEYS.FETCH_CHANNEL_MOBILES);
      return "success";
    } catch (error) {
      return "failed";
    }
  },
  deleteMobileChannel: async (channelid) => {
    try {
      await axios.delete(`${API_MOBILE_CHANNEL}/${channelid}`);
      CacheService.remove(CACHEKEYS.FETCH_CHANNEL_MOBILES);
      return true;
    } catch (error) {
      return false;
    }
  }
}


export default ChannelService;

export {
  createChannel,
  getChannels,
  deleteChannel,
  getChannelDetailsByName,
  editchannel,
  refreshChannel
};
