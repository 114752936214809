import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import AppContext from "../../context/context";
import { JsonToExcel } from "react-json-to-excel";

// mui
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// components
import UtilDiv from "../../components/Utildiv";
import ServerSelect from "../../components/Serverselect";
import ChannelNumbers from "./ChannelNumbers";
import Nodata from "../../components/Nodata";
import TextField from "@mui/material/TextField";

// services
import { getChannels } from "../../service/channel.service";
import { FormatDate } from "../../utils/date.util";

// vars
import Actions from "../../context/actions";
import { useHistory } from "react-router-dom";
import HomePreloader from "../../components/Preloaders/HomePreloader";
import ChannelTable from "./ChannelTable";


// styled
const LegendDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Home = () => {
  // store variables
  const { dispatch, store } = useContext(AppContext);
  const { user, refreshChannelFlag } = store;
  const history = useHistory();

  const [data, setData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [_loading, setLoading] = useState(false);
  // eslint-disable-next-line
  // const [_selected, setSelected] = useState(null);
  const [_server, setServer] = useState();
  const [_servers, setServersList] = useState([]);
  const [_searchText, setSearchText] = useState("");


  const _makeDomainChannelSelection = (_data, _s) => {
    const params = new URLSearchParams(document.location.search);
    const qDomain = params.get('domain');
    const qChannel = params.get('channel');
    let domainToSelect = null;
    // let channelToSelect = null;
    if (qDomain !== null && qChannel !== null) {
      domainToSelect = qDomain;
      // channelToSelect = _data.find(d => d.name === qChannel)
      history.replace('/home')
    } else {
      domainToSelect = _s[0];
      // channelToSelect = _data.find(d => d.domain === _s[0])
    }
    setServer(domainToSelect);
    // setSelected(channelToSelect);
  }

  const _fetchChannels = async () => {
    setLoading(true);
    const { data, servers } = await getChannels(user);
    setData(data);
    const excel = data.map((d, index) => ({
      No: (index + 1),
      "Created Date": FormatDate(d['created_at']),
      Name: d.name,
      Domain: d.domain,
      HLS: d.hls,
      RTMP: d.rtmp,
      Preview: d['preview_url']
    }));
    setExcelData(excel)
    setServersList(["All", ...servers]);
    _makeDomainChannelSelection(data, servers)
    dispatch({
      type: Actions.SET_SERVER_LIST,
      payload: [...new Set([...servers, user.domain])],
    });
    dispatch({
      type: Actions.SET_CHANNEL_LIST,
      payload: data,
    });
    setLoading(false);
  };

  const _searchWithChannelName = (e) => {
    setSearchText(e.target.value);
  }

  useEffect(() => {
    _fetchChannels();
    // eslint-disable-next-line
  }, [user, refreshChannelFlag]);


  const _filterServer = _server || "All";
  let _channels = data.sort((a, b) => a.name.localeCompare(b.name));
  if (_filterServer !== "All") {
    _channels = data.filter((d) => d.domain === _filterServer);
  }
  _channels = _channels.filter(d => d.name.includes(_searchText.toLowerCase()));

  const onUpdatingChannel = () => {
    setLoading(true);
  }

  if (_loading) {
    return <HomePreloader />
  }

  return (
    <>
      <UtilDiv>
        <ServerSelect
          serverNames={_servers}
          onSelect={(s) => _makeDomainChannelSelection(data, [s])}
          selectedServer={_filterServer}
          labelVisible={false}
        />
        <TextField
          value={_searchText}
          onChange={_searchWithChannelName}
          sx={{ width: "300px", marginTop: "-16px" }}
          id="search-channels"
          label="Search channel"
          variant="standard"
        />
        <LegendDiv>
          <ChannelNumbers channels={_channels} />
        </LegendDiv>
        <div style={{ display: "flex", background: "#050f66", alignItems: "center", borderRadius: "4px", padding: "4px 10px" }}>
          <FileDownloadIcon sx={{ color: "white" }} fontSize="small" />
          <JsonToExcel
            title="Download as Excel"
            data={excelData}
            fileName="channels"
            btnClassName="json-to-excel-download-button"
            btnColor="#050f66"
          />
        </div>
      </UtilDiv>
      {
        _channels.length === 0 ? <Nodata message={`No channels available 
        Go to 'Servers' tab and create channel`} /> : <ChannelTable onUpdate={onUpdatingChannel} channels={_channels} />
      }
    </>
  );
};

export default Home;
