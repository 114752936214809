import React from "react";

// components
import IconButton from "@mui/material/IconButton";
import CopyIcon from "@mui/icons-material/FileCopy";
import { Links, Header, CopyDiv, Text } from "./ChannelDetailDisplayDiv";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FormatDate } from "../../utils/date.util";


const links = [
  {
    name: "Domain",
    selector: "domain",
    copy: false
  },
  {
    name: "Stream",
    selector: "stream",
    copy: true
  },
  {
    name: "Key",
    selector: "token",
    copy: true
  },
  {
    name: "RTMP",
    selector: "rtmp",
    copy: true
  },
  {
    name: "HLS",
    selector: "hls",
    copy: true
  },
  {
    name: "Preview",
    selector: "preview_url",
    copy: true
  },
];

const ChannelLinks = ({ channel }) => {
  const _onCopy = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      // setSnack(true);
    });
  };

  return (
    <Links>
      {links.map((l) => {
        const text = channel?.[l.selector];
        return (
          <CopyDiv key={l.name}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
              <Header>{l.name}</Header>
              <Text>{text}</Text>
            </div>
            {
              l["copy"] && (
                <IconButton onClick={() => _onCopy(text)}>
                  <CopyIcon fontSize="small" />
                </IconButton>
              )
            }
          </CopyDiv>
        );
      })}
      <CopyDiv key={"create-date"}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
          <Header>{"Created Date"}</Header>
          <Text>{FormatDate(channel['created_at'])}</Text>
        </div>
      </CopyDiv>
    </Links>
  );
};

const ChannelLinkModal = ({ open, onClose, channel }) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="delete-channel-confirmation"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <ChannelLinks channel={channel} />
      </DialogContent>
      <DialogActions >
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          disableElevation
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChannelLinkModal;
