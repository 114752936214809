import React from "react";
import styled from "styled-components";

// styled
const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Count = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

const ChannelNumbers = ({ channels }) => {
  const [_count, setCount] = React.useState(0);

  const _countHealthyChannels = async () => {
    let count = 0;
    for (let i = 0; i < (channels || []).length; i++) {
      const feature = channels[i]["feature"];
      if (feature["health"]) {
        count += 1;
      }
    }
    setCount(count);
  };

  React.useEffect(() => {
    _countHealthyChannels();
    // eslint-disable-next-line
  }, [channels]);

  return (
    <Div>
      <Count>Total {channels.length} </Count>
      <Count>Live {_count} </Count>
    </Div>
  );
};

export default ChannelNumbers;
