import React, { useState, useContext } from "react";
import AppContext from "../../context/context";

// mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";

// services
import { createUser } from "../../service/user.service";
import TxtField from "../TxtField";
import SaveIcon from '@mui/icons-material/Upload';
import UserDomainList from "./UserDomainList";
import { ValidateUserModel } from "../../utils/validation.util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateNewUser = ({ open, onClose, callback }) => {
  // store variables
  const { store } = useContext(AppContext);
  const { user } = store;

  // state variables
  const [creating, setCreating] = useState(false);
  const [err, setErr] = useState(null);
  const [userObj, setUserObj] = useState({
    username: "",
    password: "",
    domain: "",
    platform: "web",
    domains: []
  });

  const handleChange = (e) => {
    setUserObj({
      ...userObj,
      [e.target.name]: e.target.value,
    });
  };

  const handleDomainListChange = (newDomainArray) => {
    setUserObj({
      ...userObj,
      domains: newDomainArray,
    });
  };


  const _validEntries = async () => {
    setErr(null);
    const validationResult = ValidateUserModel(user, userObj);
    if (validationResult !== null) {
      setErr(validationResult);
      return false;
    }
    return true;
  };

  const addNewUser = async () => {
    setCreating(true);
    const isValid = await _validEntries();
    if (isValid) {
      const status = await createUser({
        ...userObj,
        "created_by": user["id"],
        "platform": userObj["platform"].toUpperCase()
      });
      if (status === "failed") {
        setErr("Error in creating user");
      } else {
        onClose();
        callback();
      }
    }
    setCreating(false);
  };

  const closePopup = () => {
    setCreating(false);
    setErr(null);
    setUserObj({
      username: "",
      password: "",
      domain: "",
      platform: "web",
      domains: []
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closePopup}
      aria-labelledby="create-user-form"
    >
      <DialogTitle id="create-user-form-title">{"Create new user"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="create-user-form-title-description">
          {err && <p style={{ color: "red" }}>{err}</p>}
          <TxtField
            id="username"
            label="Email"
            name="username"
            value={userObj.username}
            disabled={creating}
            onChange={handleChange}
          />
          <TxtField
            id="password"
            name="password"
            label="Password"
            value={userObj.password}
            disabled={creating}
            onChange={handleChange}
          />
          {/* <TxtField
            id="platform"
            name="platform"
            label="Platform"
            value={userObj["platform"]}
            disabled={creating || user["type"] !== "SUPER_ADMIN"}
            onChange={handleChange}
          /> */}
          <UserDomainList domains={userObj["domains"]} onChange={handleDomainListChange} />
        </DialogContentText>
        {creating && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={addNewUser}
          variant="contained"
          color="success"
          disableElevation
          disabled={creating}
          startIcon={<SaveIcon />}
          sx={{ textTransform: "none" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewUser;
