import React, { useState } from "react";
import styled from "styled-components";

// mui-components
import { Button, Stack } from "@mui/material";
//icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// components
import Preloader from "../../components/Preloader";
import CreateNewChannel from "../../components/Channel/Createchannel";
import { TableContainerStyled, TableCellStyled, Table, TableBody, TableRow } from "../../components/TableComponents";

// services
import {
  rebootServer,
} from "../../service/domain.service";
import Legend from "../../components/Legend";

// styled
const ServersPage = styled.div``;

const Info = styled.p`
  opacity: 0.4;
  text-align: right;
  margin-bottom: 8px;
`;

const ServersA = ({ servers, map }) => {
  // stat variabled
  const [_loading, setLoading] = useState({
    status: false,
    message: ""
  });
  const [_opencreate, setOpencreate] = useState(false);
  const [_server, setServer] = useState("");

  const _rebootServer = async (_sname) => {
    setLoading({
      status: true,
      message: "Rebooting server..."
    });
    await rebootServer(_sname);
    setTimeout(() => {
      setLoading({
        status: false,
        message: ""
      });
    }, 2 * 1000);
  };

  if (_loading["status"]) {
    return <Preloader message={_loading["message"]} />;
  }

  return (
    <ServersPage>
      <Info>Use action column to change server status*.</Info>
      <TableContainerStyled>
        <Table aria-label="channel-list">
          <TableRow>
            <TableCellStyled align="left">Domain</TableCellStyled>
            <TableCellStyled align="left">Channel Limit</TableCellStyled>
            <TableCellStyled align="left"># Channels</TableCellStyled>
            <TableCellStyled align="left">Status</TableCellStyled>
            <TableCellStyled align="left">Secured</TableCellStyled>
            {/* <TableCellStyled align="left">Version</TableCellStyled> */}
            <TableCellStyled align="left">Actions</TableCellStyled>
          </TableRow>
          <TableBody>
            {servers.map((s, index) => {
              const _sname = s.name;
              const _limit = s["channel_limit"];
              const _used = map[_sname] || "N/A";
              return (
                <TableRow key={s + " " + index}>
                  <TableCellStyled align="left">{_sname}</TableCellStyled>
                  <TableCellStyled align="left">{_limit}</TableCellStyled>
                  <TableCellStyled align="left">{_used}</TableCellStyled>
                  <TableCellStyled align="left">
                    <Legend type={"live"} />
                  </TableCellStyled>
                  <TableCellStyled align="left">
                    <LockOutlinedIcon sx={{ color: "green" }} />
                  </TableCellStyled>
                  {/* <TableCellStyled align="left">{s.version}</TableCellStyled> */}
                  <TableCellStyled align="left">
                    <Stack direction="row" spacing={2}>
                      <Button
                        sx={{ textTransform: "none" }}
                        size="small"
                        disableElevation
                        disabled={_used >= _limit}
                        variant="outlined"
                        onClick={() => {
                          setServer(_sname);
                          setOpencreate(true);
                        }}
                      >
                        Add Channel
                      </Button>
                      <Button
                        sx={{ textTransform: "none" }}
                        disableElevation
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => _rebootServer(_sname)}
                      >
                        Reboot
                      </Button>
                    </Stack>
                  </TableCellStyled>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <CreateNewChannel
        open={_opencreate}
        onClose={() => setOpencreate(false)}
        callback={() => { }}
        server={_server}
      />
    </ServersPage>
  );
};

export default ServersA;
