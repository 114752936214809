const Actions = {
  SET_USER: "SET_USER",
  SET_CHANNEL_LIST: "SET_CHANNEL_LIST",
  SET_APPNAME: "SET_APPNAME",
  SET_SERVER_LIST: "SET_SERVER_LIST",
  SET_USER_LIST: "SET_USER_LIST",
  SHOW_ALERT: "SHOW_ALERT",
  HIDE_ALERT: "HIDE_ALERT",
  SET_REFRESH_CHANNEL_FLAG: "SET_REFRESH_CHANNEL_FLAG",
  SHOW_GLOBAL_LOADER:"SHOW_GLOBAL_LOADER",
  HIDE_GLOBAL_LOADER:"HIDE_GLOBAL_LOADER"
};

export default Actions;
