import React, { useState, useContext, useEffect } from "react";
import AppConext from "../../context/context";
import Actions from "../../context/actions";

// services
import { getApp } from "./login.config";
import LoginStreamwell from "./login.streamwell";
import LoginOthers from "./login.others";
// import CACHEKEYS from "../../cacheKeys";

const Login = () => {

  // store varibles
  const { dispatch } = useContext(AppConext);

  // state variables
  const [_app, setApp] = useState({
    name: "",
    phone: "",
  });

  const _setAppName = () => {
    const location = window.location.href;
    const _a = getApp(location);
    setApp(_a);
    dispatch({
      type: Actions.SET_APPNAME,
      payload: _a,
    });
  };

  useEffect(() => {
    _setAppName();
    // eslint-disable-next-line
  }, []);

  return _app["name"] === "STREAMWELL" ? <LoginStreamwell /> : <LoginOthers />
};


export default Login;
