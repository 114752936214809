const CACHEKEYS = {
  FETCH_CHANNELS: "FETCH_CHANNELS",
  FETCH_USERS: "FETCH_USERS",
  SESSION_AUTH_DATA: "SESSION_AUTH_DATA",
  FETCH_VIEW_CHANNEL: "FETCH_VIEW_CHANNEL",
  FETCH_VIEWS: "FETCH_VIEWS",
  FETCH_SERVERS: "FETCH_SERVERS",
  FETCH_SERVER_NAME: "FETCH_SERVER_NAME",
  FETCH_CHANNEL_MOBILES : "FETCH_CHANNEL_MOBILES"
};

export default CACHEKEYS;
