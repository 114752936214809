import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const users = 5;
const rows = 5;

export default function UsersPreloader() {
  return (
    <>
      {
        Array(rows).fill(1).map(() => <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          flexWrap: 'revert',
          marginTop: "32px"
        }} >
          {
            Array(users).fill(1).map((_, i) => <Skeleton
              key={`users-card-${i}`}
              height={'100px'}
              width={'275px'}
            />)
          }
        </div>)
      }
    </>
  )
}
