import styled from "styled-components";

const Activepage = styled.div`
  width: 100%;
  overflow-x: hidden;
  margin: 1px auto;
  padding: 32px 32px 0 32px;
  max-width: 1536px;
  background-color:smokewhite;
`;

export default Activepage;
