import Constants from "../constants"

const AdminUtil = {
  isSuperAdmin: (user) => {
    return user["type"] === Constants.user_type["SUPER_ADMIN"];
  },
  isAdmin: (user) => {
    return user["type"] === Constants.user_type["ADMIN"];
  },
  isUser: (user) => {
    return user["type"] === Constants.user_type["USER"];
  }
}

export default AdminUtil;