import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../context/context";

// mui components
import Button from "@mui/material/Button";
import TxtField from "../../components/TxtField";

import Actions from "../../context/actions";
//services
import FeatureService from "../../service/feature.service";
import { Grid } from "@mui/material";

const ChannelRtmpEdit = ({ channel, onSave }) => {
  // store variable
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  //state vairable
  const [_max_rtmp, setMaxRtmp] = useState(channel.feature.max_rtmp);

  const _updateMaxRtmpCount = async () => {
    onSave();
    await FeatureService.editFeatures(channel, {
      "max_rtmp": _max_rtmp,
    });
    history.replace(`/home?domain=${channel.domain}&channel=${channel.name}`)
    dispatch({
      type: Actions.SET_REFRESH_CHANNEL_FLAG,
    })
  }

  return (
    <Grid container alignItems="center" gap={2} >
      <Grid item lg={3} >
        <TxtField type="number" onChange={(e) => setMaxRtmp(parseInt(e.target.value))} value={_max_rtmp} size="small" />
      </Grid>
      <Grid item  >
        <Button
          onClick={_updateMaxRtmpCount}
          variant="contained"
          color="success"
          disableElevation
          disabled={_max_rtmp === channel.feature.max_rtmp || isNaN(_max_rtmp)}
          sx={{ textTransform: "none" }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

export default ChannelRtmpEdit;