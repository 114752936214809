import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// const tiles = 6;
// const info = 4;

// const displayStyle = {
//   width: '100%',
//   height: '100%',
//   display: 'flex',
//   flexDirection: 'row',
//   gap: '16px'
// }

// export default function HomePreloader() {
//   return (
//     <div style={displayStyle} >
//       <div style={{ flex: 1 }} >
//         {
//           Array(tiles).fill(1).map(i => <Skeleton style={{ marginBottom: '8px' }} key={`home-tile-${i}`} height={'100px'} />)
//         }
//       </div>
//       <div style={{ flex: 4 }} >
//         <Skeleton height={'48px'} />
//         <Skeleton height={'350px'} style={{ marginBottom: '16px', marginTop: '8px' }} />
//         <div style={{
//           ...displayStyle,
//           height: 'auto'
//         }} >
//           <div style={{ flex: 1 }} >
//             {
//               Array(info).fill(1).map(i => <Skeleton style={{ marginBottom: '8px' }} key={`home-info-${i}`} height={'48px'} />)
//             }
//           </div>
//           <div style={{ flex: 1 }} >
//             {
//               Array(info).fill(1).map(i => <Skeleton style={{ marginBottom: '8px' }} key={`home-info-${i}`} height={'48px'} />)
//             }
//           </div>
//         </div>
//       </div>
//     </div>

//   )
// }


const rows = 10;

export default function HomePreloader() {
  return (
    <div style={{ marginTop: '32px' }} >
      {
        Array(rows).fill(1).map((_, i) => <Skeleton key={`stats-ch-${i}`} height={'64px'} />)
      }
    </div>
  )
}