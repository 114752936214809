import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import AppConext from "../../context/context";
import Actions from "../../context/actions";

// mui
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import PersonIcon from '@mui/icons-material/Person';
import InputAdornment from "@mui/material/InputAdornment";
import EyeOn from "@mui/icons-material/Visibility";
import EyeOff from "@mui/icons-material/VisibilityOff";


// components
import TextField from "@mui/material/TextField";
import KeyIcon from '@mui/icons-material/Key';

// services
import { getUser } from "../../service/user.service";
import CacheService from "../../service/cache.service";

const TxtFldLabel = styled.p`
  margin-bottom:8px;
`


export default function UserNamePassword({ namePlaceholder, passwordMargin }) {
  const history = useHistory();

  // store varibles
  const { dispatch } = useContext(AppConext);

  // state variables
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [logingin, setloginin] = useState(false);
  const [error, seterror] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const handleusername = (e) => {
    setusername(e.target.value);
  };

  const handlepass = (e) => {
    setpassword(e.target.value);
  };

  const _routeToHome = (_u) => {
    if (_u === null) {
      setloginin(false);
      seterror(true);
      return;
    } else {
      dispatch({
        type: Actions.SET_USER,
        payload: _u,
      });
      history.push("/");
    }
  };

  const _loginUser = async () => {
    if (username.length > 0 && password.length > 0) {
      setloginin(true);
      CacheService.clear();
      const user = await getUser(username, password);
      _routeToHome(user);
    }
  };

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  return (
    <>
      <TxtFldLabel>Username/Email</TxtFldLabel>
      <TextField
        placeholder={namePlaceholder || ""}
        id="username"
        size="small"
        fullWidth
        sx={{ marginBottom: "24px" }}
        value={username}
        onChange={handleusername}
        disabled={logingin}
        inputProps={{
          style: {
            paddingLeft: 20,
          }
        }}
        InputProps={{
          startAdornment: (
            <PersonIcon position="start" />
          ),
        }} />
      <TxtFldLabel>Password</TxtFldLabel>
      <TextField
        id="password"
        placeholder="******"
        size="small"
        type={showPass ? "text" : "password"}
        fullWidth
        sx={{ marginBottom: passwordMargin || "18px" }}
        value={password}
        onChange={handlepass}
        disabled={logingin}
        inputProps={{
          style: {
            paddingLeft: 20,
          }
        }}
        InputProps={{
          startAdornment: (
            <KeyIcon position="start" />
          ),
          endAdornment: (
            <InputAdornment position="end">
              {!showPass ? (
                <EyeOff
                  sx={{ cursor: "pointer" }}
                  onClick={togglePassword}
                />
              ) : (
                <EyeOn
                  sx={{ cursor: "pointer" }}
                  onClick={togglePassword}
                />
              )}
            </InputAdornment>
          ),
        }} />
      {
        error && (
          <p style={{ color: "red" }}>Username or Password incorrect.</p>
        )
      }
      <Button
        onClick={_loginUser}
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        sx={{ textTransform: 'none', marginTop: '48px' }}
      >
        {
          logingin ? <CircularProgress color="error" /> : "Sign In"
        }

      </Button>
    </>
  )
}