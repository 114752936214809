import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import Ourservices from "./ourservices";
import Aboutus from "./aboutus";
import RequestDemo from "./demorequest";
import NavBar from "./navbar";
import FavoriteIcon from '@mui/icons-material/Favorite';

const LandingDiv = styled.div`
  background-color:#050f66;
`
const Line1 = styled.p`
  font-size:2.5em;
  font-weight:bold;
  opacity:0.8;
  line-height:1em;
`

const Line2 = styled.p`
  font-size:3em;
  font-weight:bold;
  opacity:0.9;
  margin-top:48px;
  line-height:1em;
`

const Line3 = styled.p`
  font-size:20px;
  margin-top:48px;
`

const GooglePlayDiv = styled.div`
  background-color:#ffffff;
  padding:64px 0;
  text-align:center;
`

const AvailbleText = styled.p`
  font-size:1.5em;
  font-weight:bold;
  opacity:0.7;
`

const BottomDiv = styled.div`
  text-align:center;
  background-color:#050f66;
  padding:32px 0;
`

const CopyRights = styled.p`
  font-size:1.5em;
  font-weight:bold;
  opacity:0.8;
  color:white;
  padding: 16px 0;
`


const Landing = () => {

  const _openPlaystore = React.useMemo(() => () => {
    window.open("https://play.google.com/store/apps/details?id=in.streamwell.mobile", "_blank");

  },[])

  return (
    <>
      <LandingDiv>
        <NavBar />
        <RequestDemo />
        <div style={{ textAlign: 'center', color: 'white', padding: '32px 0' }} >
          <Line1>
            Cloud, Streaming and Support
          </Line1>
          <Line2>
            RTMP Stream Management Service
          </Line2>
          <Line3>One stop platform solution for rtmp streaming service with low latency servers customized
            for each and every user needs and requirements.
          </Line3>
          <img
            src={`landingbg.png`}
            alt="streamwell-logo"
            style={{ transform: 'scale(1.2)' }}
          />
        </div>
      </LandingDiv>
      <GooglePlayDiv>
        <AvailbleText>Available in</AvailbleText>
        <Button
          onClick={_openPlaystore}
          variant="outlined"
          disableElevation
          sx={{
            borderRadius: '25px',
            fontSize: '18px',
            textTransform: 'none',
            marginTop: '32px'
          }}
          startIcon={
            <img
              alt="Google play store"
              width="50px"
              height="50px"
              src="playstore.jpeg"
            />
          }
        >
          Google play store
        </Button>
      </GooglePlayDiv>
      <Aboutus />
      <Ourservices />
      <BottomDiv>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }} >
          <CopyRights>
            #Streamwell
          </CopyRights>
          <FavoriteIcon style={{ color: "red" }} />
        </div>
        <CopyRights>
          Copyright © 2023 StreamWell - All Rights Reserved
        </CopyRights>
      </BottomDiv>
    </>
  );
};

export default Landing;
