import React from "react";
import ReactDOM from "react-dom";
//Apps

// import AppBuilder from "./AppBuilder";
// ReactDOM.render(
//   <React.StrictMode>
//     <AppBuilder />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

import App from "./App";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
